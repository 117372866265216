import React, { useState, useEffect, useContext } from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
	Container,
	Button,
	Stack,
	TextField
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment/moment';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import {
	getGestoresAsync,
	getGestoresBySucursalAccessAsync,
	getSucursalByUserAsync
} from '../../../../api/Users';
import { getRuta, simpleMessage } from '../../../../helpers/Helpers';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectorSaldosCartera = () => {
	const [gestorList, setGestorList] = useState([]);
	const [selectedGestor, setSelectedGestor] = useState('t');

	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState('t');
	const [selectedFecha, setSelectedFecha] = useState(new Date());

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();

	useEffect(() => {
		getGestores(selectedSucursal);
		getSucursals();
	}, []);

	const verReport = () => {
		if (selectedGestor.length === 0) {
			simpleMessage('Seleccione un gestor', 'error');
			return;
		}

		var params = `${selectedSucursal}/${selectedGestor}/${moment(selectedFecha).format(
			'YYYY-MM-DD'
		)}`;
		window.open(`${ruta}/r-saldoscartera/${params}`);
	};

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getGestores(value);
	};

	const getGestores = async (sucursal) => {
		setIsLoading(true);
		const result =
			sucursal === 't'
				? await getGestoresBySucursalAccessAsync(token)
				: await getGestoresAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setGestorList(result.data.result);
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					elevation={10}
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => onChangeSucursal(e.target.value)}
								value={selectedSucursal}
								label="Sucursal"
							>
								{sucursalList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
								<MenuItem key={'t'} value={'t'}>
									TODAS...
								</MenuItem>
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ textAlign: 'left', marginTop: 20 }}>
							<InputLabel id="demo-simple-select-standard-label">Gestores</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => setSelectedGestor(e.target.value)}
								value={selectedGestor}
								label="Gestores"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Gestor</em>
								</MenuItem>
								{gestorList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.fullName}
										</MenuItem>
									);
								})}
								<MenuItem value={'t'}>TODOS...</MenuItem>
							</Select>
						</FormControl>

						<DesktopDatePicker
							closeOnSelect
							label="Seleccione una fecha"
							inputFormat="DD/MM/YYYY"
							value={selectedFecha}
							onChange={(newValue) => setSelectedFecha(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>

						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => verReport()}
							style={{
								color: '#4caf50',
								borderColor: '#4caf50'
							}}
							size="large"
						>
							Generar Reporte
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default SelectorSaldosCartera;
