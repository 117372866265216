import React, { useContext, useEffect, useState } from 'react';

import { Typography, Stack } from '@mui/material';

import { DataContext } from '../../../../context/DataContext';
import { conv2Letras } from '../../../../helpers/n2Letras';

import { getToken } from '../../../../api/Account';
import { num_Letras, simpleMessage } from '../../../../helpers/Helpers';
import { getClientFullAddressAsync } from '../../../../api/Clients';
import { isEmpty } from 'lodash';
import '../../../../components/estilos/estilos.css';
import moment from 'moment';
import { num_to_Letra } from '../../../../helpers/num_Letras';
import { getCreditGarantiasAsync } from '../../../../api/Credits';

export const PrintPagare = React.forwardRef((props, ref) => {
	const [plazoMes, setPlazoMes] = useState(0);
	const [address, setAddress] = useState('');

	const [mes, setMes] = useState('');
	const [garantiaList, setGarantiaList] = useState([]);

	const { title, slogan, icon, setIsLoading, setIsUnautorized, docTitle } =
		useContext(DataContext);
	const {
		id,
		client,
		montoPagar,
		montoEntregado,
		plazo,
		periocidad,
		cuotaPagar,
		fechaEntrega,
		fechaVencimiento,
		fechaPrimeraCuota,
		fechaCreacion,
		destino,
		interesCorriente
	} = props.dataCredit;

	const getPageMargins = () => {
		return `body {
          color:black;
        }`;
	};

	const token = getToken();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getClientFullAddressAsync(token, client.id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setAddress(result.data.result);

			await getGarantias();
			setIsLoading(false);
		})();
		setPlazoMes((periocidad.cuotaDias * plazo.cuotas) / periocidad.cantidadDias);
		let fecha = new Date(fechaEntrega);

		const monthNameLong = fecha.toLocaleString('es-NI', { month: 'long' });
		setMes(monthNameLong);
	}, []);

	const convertCedula = (cedula) => {
		return num_Letras(cedula);
	};

	const getGarantias = async () => {
		const result = await getCreditGarantiasAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setGarantiaList(result.data.result);
	};

	return isEmpty(props.dataCredit) ? (
		<></>
	) : (
		<div ref={ref}>
			<style>{getPageMargins()}</style>
			<>
				<Stack direction="row" justifyContent="space-between">
					<img src={icon} style={{ width: 70, height: 70 }} />
					<Stack>
						<Typography variant="h5" color="#212121" textAlign={'center'}>
							{title}
						</Typography>
						<Typography color="#212121" textAlign={'center'}>
							{slogan}
						</Typography>
						<h6 className="pagareHeader">
							MUTUO GARANTIZADO CON GARANTIA MOBILIARIA OTORGADO A UNA PERSONA.-
						</h6>
						{/* <Typography
							variant="h6"
							color="#212121"
							textAlign={'center'}
							fontFamily="Agency FB"
						>
							MUTUO GARANTIZADO CON GARANTIA MOBILIARIA OTORGADO A UNA PERSONA.-
						</Typography> */}
					</Stack>
					<img src={icon} style={{ width: 70, height: 70 }} />
				</Stack>

				<p className="pagareP">
					Nosotros: <strong>DIMAS OSMIN ANDRADE MENDEZ</strong>, mayor de edad, Casado,
					Comerciante, de este domicilio, identificado con Cédula de Identidad número
					Cero, nueve, dos, guion, dos, cero, cero, siete, nueve, dos, guion, cero, cero,
					cero, cero, P (092-200792-0000P), quien actúa en nombre y representación de{' '}
					<strong>SERVICIOS FINANCIEROS A y Z SOCIEDAD ANONIMA</strong>, denominación que
					se abreviará como “A y Z , <strong>S.A.</strong>”, con domicilio en el municipio
					de Chinandega, departamento de Chinandega, Republica de Nicaragua,
					<strong>
						específicamente en la dirección que cita: De donde fue la Morgue del
						Hospital Materno Infantil media cuadra al Sur
					</strong>
					, demostrando la existencia legal de su representada con los siguientes
					documentos: <strong>a)</strong> Testimonio de Escritura Publica Numero
					Ocho.-(08).- denominada Poder Generalísimo, suscrito en la ciudad de Chinandega,
					Departamento de Chinandega, a las Nueve de la mañana del día Cuatro de Marzo del
					año Dos Mil Veinticuatro, ante los oficios de la suscrita Notario Publico,
					inscrito bajo la Cuenta Registral Numero: MC-XH6YOU. b)Testimonio de Escritura
					Publica numero{' '}
					<strong>
						Cuatro.-(04) Constitución de la Sociedad Anónima y Aprobación de Sus
						Estatutos
					</strong>
					, suscrita en el municipio de Chinandega, departamento de Chinandega, a las Tres
					de la Tarde del día Veinticinco de Febrero del año Dos Mil Veinticuatro, ante
					oficios de la suscrita Notario Publico, inscrito bajo la Cuenta Registral
					Numero: MC-XH6YOU.-c) Testimonio de Escritura Número Cinco.-(05) denominada
					Inscripción de Comerciante, suscrita en la ciudad de Chinandega, departamento de
					Chinandega, a las Once de la mañana del día Dos de Marzo del año Dos Mil
					Veinticuatro, ante los oficios de la suscrita Notario Publico, inscrito bajo la
					Cuenta Registral Numero: MC-XH6YOU.-d) Certificado de Registro de Beneficiario
					Final de las Sociedades Mercantiles de Chinandega, inscrito bajo el Numero de
					Cuenta Registral MC-XH6YOU, todos inscritos en el Registro Publico de la
					Propiedad del Departamento de Chinandega. a quien en adelante se le llamara “El
					Acreedor” y el señor: <strong>{props.clientName}</strong>, mayor de edad,{' '}
					{client.civilStatus}, Comerciante, con domicilio en {address}, aquí de transito,
					identificado con Cédula de Identidad Número{' '}
					{convertCedula(client.identification)} ({client.identification}), quien actúa en
					su propio nombre y representación, a quien en adelante se le denominara Deudor
					Mobiliario.- Hemos decido celebrar el presente contrato de Mutuo Garantizado con
					Garantía Mobiliario Otorgado a Una Persona.-Habla el Señor: {props.clientName} y
					dice: <strong>CLAUSULA PRIMERA (RELACION DE CREDITO Y MUTUO)</strong> Que por
					medio de este Instrumento Público acepta haber recibido del señor{' '}
					<strong>DIMAS OSMIN ANDRADE MENDEZ</strong> , Representante Legal de{' '}
					<strong>SERVICIOS FINANCIEROS A y Z SOCIEDAD ANONIMA</strong>, denominación que
					se abreviará como “A y Z , <strong>S.A.</strong>”, el dia de hoy{' '}
					{moment().format('D')} de {moment().format('MMMM')} del corriente año un Crédito
					en calidad de Mutuo por la suma de {conv2Letras(montoEntregado)} (
					{new Intl.NumberFormat('es-NI', {
						style: 'currency',
						currency: 'NIO'
					}).format(montoEntregado)}
					), los cuales confiesa tener recibidos a su entera satisfacción en este acto. El
					deudor Mobiliario hará uso del crédito recibido en calidad de mutuo, para
					invertirlos en {destino}-{' '}
					<strong>CLAUSULA SEGUNDA (INTERES CORRIENTE Y MORATORIO):</strong> Expresan
					conjuntamente al acreedor y el deudor: Que el crédito tendrá una carga
					financiera del {num_to_Letra(interesCorriente)} por ciento mensual (
					{interesCorriente.toFixed(2)}%), porcentaje que incluye interés corriente. En
					caso de mora el presente crédito generará una carga adicional, de interés
					moratorio del Veinticinco (25) por ciento sobre el monto en mora y días
					atrasados.- <strong>CLAUSULA TERCERA (PLAZO Y FORMA DE PAGO)</strong> El crédito
					antes referido será pagado en un plazo de {plazo.description}, siendo su fecha
					de vencimiento el {num_to_Letra(moment(fechaVencimiento).format('DD'))} de
					{moment(fechaVencimiento).format('MMMM')} del{' '}
					{num_to_Letra(moment(fechaVencimiento).format('YYYY'))} y su forma de pago será{' '}
					{num_to_Letra(plazo.cuotas)} cuotas {periocidad.description}, iniciando el pago
					de la primera cuota el {num_to_Letra(moment(fechaPrimeraCuota).format('DD'))} de{' '}
					{moment(fechaPrimeraCuota).format('MMMM')} del{' '}
					{num_to_Letra(moment(fechaPrimeraCuota).format('YYYY'))}, hasta por la suma{' '}
					{conv2Letras(cuotaPagar)} (
					{new Intl.NumberFormat('es-NI', {
						style: 'currency',
						currency: 'NIO'
					}).format(cuotaPagar)}
					) CADA CUOTA, que incluyen el interés pactado, las que deberán ser depositadas
					por el deudor en las oficinas de Servicios Financieros A y Z, S.A o en manos del
					gestor de cobro de la institución debidamente identificado. La falta de pago del
					deudor de sus obligaciones en las fechas convenidas en la presente Escritura, lo
					hará incurrir en MORA sin necesidad de requerimiento o cobro alguno judicial o
					extrajudicial, debiendo reconocer los intereses penales o moratorios convenidos,
					pudiendo el acreedor dar por vencido el total de lo adeudado y proceder a su
					inmediato cobro con sólo faltar al pago de una de las cuotas convenidas.- Habla
					el Señor: {props.clientName} y dice:{' '}
					<strong>CLAUSULA CUARTA (CONSTITUCION DE GARANTIA MOBILIARIA)</strong>: Que para
					garantizar mejor el pago de la obligación contraída con{' '}
					<strong>SERVICIOS FINANCIEROS A y Z SOCIEDAD ANONIMA</strong>, denominación que
					se abreviará como “A y Z , <strong>S.A.</strong>” en este acto constituye
					GARANTIA MOBILIARIA sobre
					{garantiaList.map((item) => {
						return ` un ${item.articulo} de su propiedad con las siguientes características: marca ${item.marca}, modelo ${item.modelo}, serie ${item.serie}, color ${item.color}, `;
					})}{' '}
					la suscrita Notario doy fe de haber tenido a la vista Original de Testimonio de
					Escritura Publica numero Cuarenta y cuatro.-(44) denominado Compra Venta de
					Vehiculo, suscrita en la ciudad de{' '}
					{props.credit.length !== 0
						? props.credit.client.community.municipality.name
						: ''}{' '}
					a las {moment(fechaCreacion).format('hh a')} del dia{' '}
					{num_to_Letra(moment(fechaCreacion).format('DD'))} del mes de{' '}
					{moment(fechaCreacion).format('MMMM')} del año{' '}
					{num_to_Letra(moment(fechaCreacion).format('YYYY'))}, ante los oficios
					Notariales del Licenciado Carlos Alberto Murillo Brenes. Sigue diciendo el
					compareciente que: a) Constituye deposito convencional sobre la garantía
					mobiliaria ampliamente descrita y especificada en esta cláusula, quedando la
					misma en poder de su dueño como depositario con obligación de custodiarla,
					cuidarla, y entregarla al acreedor cuando fuere requerido para tal fin, pudiendo
					hacer cesar el presente deposito convencional una vez que cancele el crédito,
					todo conforme lo establecido en el articulo 3449 y siguientes del Código Civil
					Vigente de Nicaragua.- b) El deudor conservará a nombre del acreedor la posesión
					de los objetos pignorados, pudiendo usar de ellos sin menoscabo de su valor,
					estando obligado a realizar por su cuenta los trabajos de mantenimiento,
					conservación y reparación. c) El deudor tendrá respecto a los bienes pignorados
					los deberes y responsabilidades de los depositarios. d) El deudor se obliga a
					informar por escrito al acreedor cualquier desmérito, desmejoras, perjuicios, o
					daños que pudieren ocurrirle a los bienes pignorados, debiendo hacerlo dentro de
					un plazo no mayor de cuarenta y ocho horas de ocurrido. d) Los bienes pignorados
					solo podrán ser sacados del domicilio de deudor, que es donde se encuentran, con
					permiso escrito del acreedor. e) Queda prohibido al deudor celebrar otro
					Contrato de garantía mobiliaria sobre los bienes Pignorados. f) El deudor asume
					lo relativo y legalmente establecido en cuanto al Apremio Corporal en materia
					civil, artos 2521 C y siguientes, por lo que hace a la presentación de las
					garantías mobiliarias.-{' '}
					<strong>
						CLAUSULA QUINTA: (RECARGOS POR COBRANZA ADMINISTRATIVA Y COBRANZA
						EXTRAJUDICIAL)
					</strong>
					: EL deudor en caso de incurrir en mora, después del primer día de atraso,
					reconocerá y pagará al deudor en concepto de cobranza administrativa el diez por
					ciento (10%) calculado sobre la cuota de amortización en mora y los días
					atrasados. Habla “El deudor mobiliario” y dice:{' '}
					<strong>CLAUSULA SEXTA (VENCIMIENTO ANTICIPADO)</strong> Que el plazo prefijado
					para el Préstamo, podrá ser exigible de inmediato por el acreedor en los
					siguientes casos: a) Si se faltara al pago de una sola de las cuotas convenidas,
					en la fecha fijada. b) Si el deudor diere a los fondos un uso distinto al
					convenido en el presente contrato. c) Si el deudor fuere ejecutado judicialmente
					por cualquier persona natural o jurídica durante la vigencia del crédito. d) Si
					sobre el bien hipotecado o garantía mobiliaria recayere embargo o ejecución. e)
					Si el deudor gravare nuevamente el bien dado en hipoteca o garantía mobiliaria
					sin autorización del acreedor. f) Si se proporcionare datos o informaciones
					falsas a SERVICIOS FINANCIEROS A Y Z S.A, el fiador o los bienes Mobiliarios.-
					<strong>CLAUSULA SEPTIMA: (RENUNCIAS)</strong>.- El deudor Mobiliario y los
					fiadores solidarios dicen, RENUNCIAN: a) A las excepciones provenientes del caso
					fortuito y fuerza mayor, cuyos riesgos asumen en su totalidad. c) A los trámites
					del juicio de Ejecución Forzosa y se someten al que su Acreedor elija y a su vez
					autoriza al acreedor a subastar o rematar los bienes mobiliario o hipotecados,
					por el valor a que estuviere reducida la deuda a dicha fecha. d) Al derecho de
					ser intimado de pago para los efectos de la mora; e) A gozar del plazo del
					préstamo por la falta de pago del interés y cuotas de amortización de lo
					prestado, en tal caso el acreedor podrá dar por vencido el plazo de la
					obligación y exigir judicialmente el saldo que de la misma existiera, con la
					falta de pago de una sola cuota a las que se ha obligado; g) Al derecho de ser
					depositario de los bienes que se le embarguen; h) al derecho de perseguir
					primero la garantía constituida, pues el acreedor podrá perseguir antes, después
					o simultáneamente con el bien mobiliario, otros bienes del deudor, sin perder su
					derecho preferente; i) Al derecho de ser notificado de cualquier cesión y/o
					subrogación que de este crédito haga el acreedor a cualquier persona natural o
					jurídica. j) Al derecho de nombrar perito valuador de bienes inmuebles o muebles
					gravados. h) A que se valore en bien dado en hipoteca o garantías mobiliarias.
					Hablan en forma conjunta todos los comparecientes y dicen:{' '}
					<strong>CLAUSULA OCTAVA: (ACEPTACION)</strong>.- Que ACEPTAN, el contrato de
					MUTUO GARANTIZADO CON GARANTIA MOBILIARIA, OTORGADO A UNA PERSONA.-Y leído que
					fue a los comparecientes, la encuentran conforme, aprueban, ratifican y firman
					junto conmigo la Notario que doy fe de todo lo aquí relacionado.- Chinandega, a
					los {moment().format('D')} dias del mes de {moment().format('MMMM')} del
					corriente año.
				</p>

				<Stack direction={'row'} spacing={2} marginTop={15} justifyContent="space-around">
					<Stack justifyContent="center">
						<span>----------------------------------------------------</span>

						<Typography className="pagareP" textAlign={'center'} color="#212121">
							Dimas Osmin Andrade Mendez
						</Typography>
						<Typography className="pagareP" textAlign={'center'} color="#212121">
							Acreedor
						</Typography>
					</Stack>
					<Stack justifyContent="center">
						<span>----------------------------------------------------</span>
						<Typography className="pagareP" textAlign={'center'} color="#212121">
							{props.clientName}
						</Typography>
						<Typography className="pagareP" textAlign={'center'} color="#212121">
							Deudor Mobiliario
						</Typography>
					</Stack>
				</Stack>
			</>
		</div>
	);
});

export default PrintPagare;
